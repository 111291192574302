import React from 'react';
import Button from "../Button/Button";
import alert from '../../img/alert.png'

function Acompanhing({ acompanhing }) {
    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
            <h1 style={{ textAlign: 'center', marginBottom: '20px', fontSize: '26px' }}><strong>Canal de Denúncias e Sugestões Anônimas</strong></h1>
            <h1 style={{ textAlign: 'center', marginBottom: '20px', fontSize: '26px' }}><strong>Acompanhamento</strong></h1>
            {acompanhing?.map((item, index) => (
                <div
                    key={index}
                    style={{
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        padding: '15px',
                        marginBottom: '15px',
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                        backgroundColor: '#fff',
                    }}
                >
                    {item.status === 1 && (
                        <div className="bg-yellow-300 text-black p-2 rounded mb-4 text-center font-bold flex items-center justify-center gap-2">
                            <img src={alert} alt="Alerta" className="h-5 w-auto" />
                            Seu relato está em análise. Por favor, aguarde.
                            <img src={alert} alt="Alerta" className="h-5 w-auto" />
                        </div>
                    )}
                    <div style={{ marginBottom: '10px' }}>
                        <strong>Protocolo:</strong> {item.protocolo}
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <strong>Status:</strong>{' '}
                        {item.status === 0
                            ? 'Pendente'
                            : item.status === 1
                                ? `Em Analise. Última atividade em ${new Date(item.dt_status).toLocaleString('pt-BR', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit',
                                })}`
                                : item.status === 2
                                    ? 'Concluído'
                                    : ''}

                        {item.status === 1 && (
                            <div style={{ fontStyle: 'italic', fontSize: 'small', color: 'gray', marginTop: '5px' }}>
                                O seu relato está em investigação. Por favor, consulte novamente mais tarde. O prazo de análise e retorno é
                                de até 7 dias úteis. Obrigado.
                            </div>
                        )}
                    </div>

                    <div style={{ marginBottom: '10px' }}>
                        <strong>Relato:</strong>
                        <p style={{ margin: '5px 0', whiteSpace: 'pre-wrap' }}>{item.mensagem || 'N/A'}</p>
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <strong>Data do relato:</strong> {new Date(item.dt_mensagem).toLocaleString('pt-BR', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit'
                        })}
                    </div>
                    {item.resposta ? (
                        <div style={{ marginBottom: '10px' }}>
                            <strong>Resposta:</strong>
                            <p style={{ margin: '5px 0' }}>{item.resposta}</p>
                        </div>) : ('')}

                    {item.dt_resposta ? (<div style={{ marginBottom: '10px' }}>
                        <strong>Concluído em:</strong>{' '}
                        {new Date(item.dt_resposta).toLocaleString('pt-BR', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit'
                        })}
                    </div>) : ('')}

                </div>
            ))}
            <div className="flex justify-center">
                <Button
                    onClick={() => window.location.reload()}
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                    text='Voltar'
                    // loading={loading}
                    color='bg-blue-500'
                />
            </div>

        </div>
    );
}

export default Acompanhing;
