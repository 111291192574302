import logo from '../../img/logo-esplane.png'
import { useState } from "react";
import axios from "axios";
import Button from "../Button/Button";

function Header({ onAccompanhingChange }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [protocol, setProtocol] = useState("");
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState('');
    // const [loadingProtocolo, setIsLoadingProtocolo] = useState(false    )



    const apiUrl = process.env.REACT_APP_VRCL === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API;
    console.log(process.env.REACT_APP_API)
    const handleSend = () => {
        if (protocol.trim()) {
            console.log(`Protocolo enviado: ${protocol}`);

            const data = {
                protocolo: protocol
            }

            setLoading('fa fa-circle-o-notch fa-spin');
            console.log(`${apiUrl}/denuncia/acompanhamento/`)
            axios.post(`${apiUrl}/denuncia/acompanhamento/`, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "ooooooo",
                },
            })
                .then((response) => {
                    setLoading('');
                    onAccompanhingChange(response.data)
                    setIsModalOpen(false); // Fecha o modal
                    // console.log(response.data);


                    // onSubmit(response);
                })
                .catch((error) => {
                    console.log(error.message);
                    setLoading(true);
                });
            setHasError(false); // Remove o estado de erro

        } else {
            setHasError(true); // Indica erro no input
            console.log("Por favor, insira um número de protocolo.");
        }
    };

    return (
        <>
            <header className="bg-black flex">
                <div className="w-3/4 mx-auto flex flex-row items-center">
                    <div className="h-16 pr-2">
                        <img src={logo} alt="Esplane" className="h-14 pt-3" />
                    </div>
                    <div className="text-white text-inter flex-start pl-3 border-l-2">
                        <h1>Central de Ajuda</h1>
                    </div>
                    <div className="text-white text-inter flex-start ml-auto">
                        <button
                            onClick={() => setIsModalOpen(true)}
                            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                            loading={''}
                        >
                            Acompanhar PROTOCOLO
                        </button>
                    </div>
                </div>
            </header>

            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-96">
                        <h2 className="text-xl font-bold mb-4">Insira o protocolo</h2>
                        <input
                            type="text"
                            placeholder="Número do protocolo"
                            value={protocol}
                            onChange={(e) => setProtocol(e.target.value)}
                            className={`w-full p-2 border ${hasError ? "border-red-500" : "border-gray-300"
                                } rounded mb-4`}
                        />
                        {hasError && (
                            <p className="text-red-500 text-sm mb-2">
                                O número do protocolo é obrigatório.
                            </p>
                        )}
                        <div className="flex justify-end space-x-4">
                            <Button
                                onClick={() => setIsModalOpen(false)}
                                className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                text='Fechar'
                                color='bg-red-600'
                            />

                            <Button
                                onClick={handleSend}
                                className={`bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded`}
                                text='Acompanhar'
                                color='bg-blue-500'
                                loading={loading}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Header;