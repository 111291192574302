import { useEffect } from 'react';

function Sucess({ protocolo }) {
    useEffect(() => {
        // Rola para o topo ao montar o componente
        window.scrollTo(0, 0);
    }, []);


    return (
        <div className="h-96 w-3/5 max-sm:w-4/5 mx-auto flex flex-col gap-5 font-inter pt-16">
            <h1 className="text-black font-extrabold text-4xl max-sm:text-2xl">Relato Enviado com Sucesso! 🎉</h1>
            <p className="text-base">
                <strong>Número do Protocolo:</strong> <span className="text-blue-600 font-bold text-xl"> {protocolo}</span>
            </p>
            <p className="text-base text-yellow-500 font-semibold">
                ⚠️ Guarde este número em um local seguro! Você precisará dele para consultar a resposta no botão
                <span className="italic"> “Acompanhar PROTOCOLO”</span> nesta página.
            </p>
            <h2 className="text-lg font-bold mt-4">⏳ Prazo para Resposta:</h2>
            <p className="text-base">Até 7 dias úteis.</p>
            <h2 className="text-lg font-bold mt-4">⚠️ Atenção:</h2>
            <p className="text-base">
                Se perder o protocolo, não será possível recuperar o relato ou consultar a resposta.
            </p>
        </div>

    );
}

export default Sucess;