import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Content from './components/Page/Content';
import { useState } from "react";

function App() {
  const [accompanhing, setAccompanhing] = useState(null);

  const handleAccompanhingChange = (value) => {
    setAccompanhing(value);
    console.log(accompanhing)
  };

  return (
    <>
      <Header onAccompanhingChange={handleAccompanhingChange} />
      <Content acompanhing={accompanhing} />
      <Footer />
    </>
  );
}

export default App;
